import React from "react";
import { navigate } from "gatsby";
import { Card, Form, Input, Button, Row, Col, Spin, Icon } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


import LogoImage from "@mc/common/components/mimecast-logo-white";
import { getLoginUrlParams, getAlertUrlParams } from "@mc/common/lib/url-params";
import { handleLoginVerify, handleLoginRequest, isLoggedIn, getRedirectPath, validateSSO, setUser } from "@mc/common/lib/auth";
import { extractIdToken } from "@mc/common/lib/strings";

import 'antd/dist/antd.css'
import "../components/layout.css"
import SEO from "../components/seo";

const loadingIcon = <Icon type="more" style={{ fontSize: 64 }} spin />;

class LoginPage extends React.Component {
  state = {
    email: '',
    submitting: false,
    submitted: false,
    validating: false
  }

  onChange = (e) => {
    this.setState({ email: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    handleLoginRequest(this.state.email).then(() => {
      this.setState({ submitting: false, submitted: true })
    }).catch(() => {
      this.setState({ submitting: false })
    });
  }

  componentDidMount() {
    this.setState({validating: true});
    
    const params = getLoginUrlParams(this.props.location);
    const alertParams = getAlertUrlParams(this.props.location);
    handleLoginVerify(params['user_id'], params['token'], alertParams['cid'], alertParams['ep'], alertParams['dep']);
    if (isLoggedIn()) {
      navigate('/');
    }

    const ssoIdToken = extractIdToken(window.location.href);
    if (ssoIdToken !== '') {
      validateSSO(ssoIdToken).then((response) => {
        setUser({
          email: response.data.email,
          is_domain_admin: response.data.is_domain_admin,
          session: response.data.session,
          loggedIn: true,
          isLoggingInSSO: true
        });
        const redirectPath = getRedirectPath();
        navigate(redirectPath);
      }).catch(() => {
        navigate('/');
      });
    } else {
      this.setState({validating: false});
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: '#1976D2',
          width: '100%',
          height: '100vh'
        }}
      >
        <Row>
          <Col span={24}>
            <div style={{
              maxWidth: '148px',
              maxHeight: '24px',
              width: 'auto',
              margin: 'auto',
              paddingTop: 60,
              marginBottom: '60px'

            }}>
              <LogoImage />
            </div>
          </Col>
        </Row>
        {this.state.validating && (
          <Row>
            <Col span={24}>
              <div style={{
                maxWidth: '148px',
                maxHeight: '24px',
                width: 'auto',
                margin: 'auto',
                marginBottom: '60px',
                paddingLeft: 35
              }}>
                <Spin spinning={this.state.validating} indicator={loadingIcon} />
              </div>
            </Col>
          </Row>
        )}
        {!this.state.validating && (
          <Row>
            <Col span={24}>
              <SEO title="Login" />
              <Card title={'Enter your email address'}
                headStyle={{
                  fontFamily: "Open Sans",
                  fontSize: '20px',
                  fontWeight: '600px',
                  letterSpacing: '0',
                  lineHeight: '27px',
                  width: '100%',
                  borderBottom: 0,
                }}
                style={{
                  fontFamily: "Open Sans",
                  height: '100%',
                  width: '100%',
                  margin: 'auto',
                  maxWidth: 356,
                  borderRadius: '6px',
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',
                }}
                bodyStyle={{ paddingTop: '0px', }}>
                  <Form onSubmit={this.onSubmit}>
                    <Form.Item style={{ marginBottom: 20 }}>
                      <Input placeholder="user@emailexample.com" type="email"
                        value={this.state.email} disabled={this.state.submitted} onChange={this.onChange} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                      <Button block disabled={this.state.submitted} style={{
                        backgroundColor: '#1976D2',
                      }} type="primary" htmlType="submit" loading={this.state.submitting} >
                        Submit
                      </Button>
                    </Form.Item>
                    {this.state.submitted && (
                      <span style={{ marginTop: 20 }}>
                        <FontAwesomeIcon style={{ marginRight: 5 }} icon={faCheckCircle} size='1x' color='#388E3C' />
                          Check your inbox!
                      </span>
                    )}
                </Form>
              </Card>
            </Col>
          </Row>    
        )}
        <Row>
          <Col span={24}>
            <div style={{
              color: '#FFFFFF',
              width: 'auto',
              margin: 'auto',
              fontSize: '12px',
              letterSpacing: '0',
              lineHeight: '17px',
              textAlign: 'center',
              marginTop: 10
            }}>
              ©{new Date().getFullYear()}  Mimecast v4.2.29 20200813-202704
              <br />
              <a style={{ color: 'inherit', }} target="_blank" rel="noopener noreferrer" href='https://www.mimecast.com/company/mimecast-trust-center/gdpr-center/privacy-statement/'>Privacy</a>
            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default LoginPage
